<template>
  <v-card class="card-reel">
    <v-img height="190px" :src="srcImage" class="grey darken-4 img-reel">
      <div class="actions-card">
        <v-spacer></v-spacer>
        <!-- <v-chip dark :color="item.visualize ? 'success' : 'secondary'" class="ma-2" small>
          {{ item.visualize ? "Activo" : "Inactivo" }}
        </v-chip> -->
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="1" v-bind="attrs" v-on="on" icon color="primary">
              <v-icon color="#fafafa" size="20">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('edit', item)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('remove', item)">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div :class="`container-slide`">
        <h4 class="title-slide">
          {{ item.title }}
        </h4>
        <div v-if="item['url-btn']" class="actions-slide">
          <a :href="item['url-btn']" target="_blank" class="link-slide">{{ item['text-btn'] }}</a>
        </div>
      </div>
    </v-img>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
export default {
  props: {
    item: Object,
    srcImage: String,
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-reel {
  .actions-card {
    position: absolute;
    right: 0;
    top: 0;
  }
  .container-slide {
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 80%;
    transform: translate(-50%, -50%);
    .title-slide {
      font-size: 17px;
      font-weight: bold;
      color: #fff !important;
    }
    .link-slide {
      padding: 0.5em 1em;
      font-size: 9px;
      background: #e83964;
      text-decoration: none;
      color: #fff;
    }

    @media screen and (max-width: 550px) {
      max-width: 90%;
    }
  }
}
.img-reel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
</style>
