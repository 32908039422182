<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formReel" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="title-reel">Título</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="title-reel"
              v-model="payload['title']"
              outlined
              dense
              placeholder="Título reel"
              :autocomplete="autocomplete"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="text-btn-reel">Texto botón</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="text-btn-reel"
              v-model="payload['text-btn']"
              outlined
              dense
              placeholder="Ver más"
              :autocomplete="autocomplete"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="url-reel">URL botón</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="url-reel"
              v-model="payload['url-btn']"
              outlined
              dense
              placeholder="http://emilima.com.pe/rubros"
              :autocomplete="autocomplete"
              :rules="[...required, ...isURL]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file-input-reel">Imagen</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file-input-reel"
              v-model="image"
              outlined
              dense
              placeholder="Selecciona una imagen"
              :autocomplete="autocomplete"
              :rules="payloadEdited ? [] : required"
              chips
            ></v-file-input>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
export default {
  components: {
    FormTemplate,
  },
  props: {
    payloadEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      isURL: [
        v =>
          !v ||
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v,
          ) ||
          'El URL debe tener https:// o http://',
      ],
      autocomplete: 'off',
      isEdited: this.payloadEdited ? true : false,
      image: null,
      payload: this.payloadEdited ? { ...this.payloadEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formReel.validate()
    },
    reset() {
      this.payload['title'] = ''
      this.payload['text-btn'] = ''
      this.payload['url-btn'] = ''
      this.image = null
      this.$refs.formReel.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      const formData = new FormData()
      formData.append('title', this.payload.title)
      formData.append('text-btn', this.payload['text-btn'])
      formData.append('url-btn', this.payload['url-btn'])
      if (this.image) {
        formData.append('file', this.image)
      }
      const DTO = {
        id: this.payload.id,
        formData,
      }
      // // console.log(DTO)
      if (this.isEdited) {
        this.$emit('beforeUpdate', DTO)
      } else {
        this.$emit('beforeCreate', DTO)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
