<template>
  <v-card elevation="0">
    <HeaderView title="Reels" @openToAdd="openDialog" :noSearch="true"></HeaderView>
    <ListReels :reels="reels" @edit="openEdit" @remove="openRemove" />
    <WDialog
      width="500"
      :dialog="dialog"
      :closeDialog="closeDialog"
      :title="titleDialog"
      :subtitle="subtitleDialog"
    >
      <template v-slot:form-dialog>
        <FormReel
          v-if="dialog"
          :loading="loading"
          :payloadEdited="reelEdited"
          @beforeUpdate="updateReel"
          @beforeCreate="createReel"
        ></FormReel>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import FormReel from "@/components/forms/FormReel.vue";
import ListReels from "./ListReels.vue";
import HeaderView from "@/components/HeaderView.vue";
import WDialog from "@/components/dialogs/WDialog.vue";
import useReels from "@/composables/useReels";

export default {
  components: {
    FormReel,
    HeaderView,
    ListReels,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      reelEdited,
      reels,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      createReel,
      updateReel,
      openRemove,
    } = useReels();

    return {
      dialog,
      reelEdited,
      reels,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      createReel,
      updateReel,
      openRemove,
    };
  },
};
</script>

<style lang="scss" scoped></style>
