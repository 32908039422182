import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useReels = () => {

    // base data
    const dialog = ref(false);
    const reelEdited = ref(null);
    const reels = ref([]);
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() => reelEdited.value ? "Editar reel" : "Agregar reel");
    const subtitleDialog = computed(() =>
        reelEdited.value ?
            "Rellene los campos correctamente para modificar los datos del reel" :
            "Rellene los campos correctamente para agregar un nuevo reel"
    );

    // methods
    const openDialog = () => {
        dialog.value = true;
    }
    const openEdit = (reel) => {
        dialog.value = true;
        reelEdited.value = { ...reel };
    }
    const closeDialog = () => {
        dialog.value = false;
        reelEdited.value = null;
    }

    // crud
    const getReelsList = async () => {
        const { data, status } = await store.dispatch("fetchReels")
        if (status != 200) return;
        // console.log("reels", data)
        reels.value = data;
    }

    const createReel = async (reel) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createReel", reel)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getReelsList();
        closeDialog();
    }

    const updateReel = async (reel) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updateReel", reel)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getReelsList();
        closeDialog();
    }

    const removeReel = async (user) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removeReel", user)
        if (status != 200 && status != 201 && status != 204) return;
        await getReelsList();
    }

    getReelsList();


    return {
        dialog,
        reelEdited,
        reels,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        openDialog,
        openEdit,
        closeDialog,
        // crud
        createReel,
        updateReel,
        openRemove: removeReel,
    };

}

export default useReels
